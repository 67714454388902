import { render, staticRenderFns } from "./ECommerceWishlist.vue?vue&type=template&id=16310aac&"
import script from "./ECommerceWishlist.vue?vue&type=script&lang=js&"
export * from "./ECommerceWishlist.vue?vue&type=script&lang=js&"
import style0 from "./ECommerceWishlist.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jasontaylor/Sites/Cancom_Secure_Portal_2024/cancom-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16310aac')) {
      api.createRecord('16310aac', component.options)
    } else {
      api.reload('16310aac', component.options)
    }
    module.hot.accept("./ECommerceWishlist.vue?vue&type=template&id=16310aac&", function () {
      api.rerender('16310aac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue"
export default component.exports